<template>
  <div class="logo" :class="{'mobile' : isMobile}" @click="$emit('click')">
    <flex-row class="logo-icon" align-v="center" noWrap>
      <svg width="36" height="31" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6058 0.5L30.8877 8.0074V23.0972L17.6058 30.6568L4.32397 23.0972V8.0074L17.6058 0.5ZM7.41278 9.78532V21.3254L17.6058 27.1269L27.7989 21.3254V9.78532L17.6058 4.02383L7.41278 9.78532Z" fill="white"/>
        <path d="M35.2124 4.82007V23.0761H32.1236V10.1109L17.6548 18.3551L3.0888 10.1033V23.0761H0V4.82766L17.6504 14.8268L35.2124 4.82007Z" fill="white"/>
      </svg>
    </flex-row>
    <flex-row class="logo-text" align-v="center" noWrap>
      <svg width="112" height="12" viewBox="0 0 112 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.802856 1.26013H3.35406L6.0908 7.18983H5.95164L8.68839 1.26013H11.1468V10.9591H9.33779V3.28266H9.6161L6.55466 9.90186H5.39502L2.37996 3.32863H2.56551V10.9591H0.802856V1.26013Z" fill="white"/>
        <path d="M12.9094 10.9591L16.2492 1.26013H18.4757L21.6763 10.9591H19.8209L19.1251 8.8906H15.507L14.8112 10.9591H12.9094ZM15.9245 7.5116H18.6612L17.2697 3.32863L15.9245 7.5116Z" fill="white"/>
        <path d="M23.3928 10.9591V1.26013H27.0109C28.1705 1.26013 29.0982 1.53593 29.7012 2.08753C30.3043 2.63913 30.629 3.32863 30.629 4.24796C30.629 5.12133 30.3043 5.8568 29.7012 6.36243C29.0982 6.91403 28.1705 7.18983 27.0109 7.18983H25.1555V10.9591H23.3928ZM25.1555 5.9947H26.8253C27.4747 5.9947 27.985 5.8568 28.3097 5.53503C28.6344 5.25923 28.8199 4.84553 28.8199 4.38586C28.8199 3.9262 28.6808 3.5125 28.3561 3.2367C28.0314 2.9609 27.6139 2.77703 27.0109 2.77703H25.1555V5.9947ZM28.6808 10.9591L26.0832 6.4084H27.985L31 10.9591H28.6808Z" fill="white"/>
        <path d="M36.473 11.143C35.7773 11.143 35.1743 11.0051 34.6176 10.7753C34.061 10.5454 33.5971 10.1777 33.2261 9.71804C32.855 9.25837 32.6694 8.61484 32.6694 7.87937H34.4321C34.4321 8.2471 34.5249 8.56887 34.7104 8.7987C34.8959 9.02854 35.1279 9.21241 35.4526 9.3503C35.7773 9.4882 36.102 9.53417 36.473 9.53417C36.8441 9.53417 37.1688 9.4882 37.4471 9.39627C37.7255 9.30434 37.9574 9.16644 38.0965 8.98257C38.2821 8.79871 38.3285 8.61484 38.3285 8.33904C38.3285 8.01727 38.2357 7.74147 38.0502 7.5576C37.8646 7.37374 37.5399 7.18987 37.0761 7.05197L35.1279 6.27054C34.3857 5.99474 33.7827 5.62701 33.4116 5.25927C33.0405 4.89154 32.8086 4.33994 32.8086 3.65044C32.8086 2.86901 33.1333 2.22547 33.7363 1.71984C34.3857 1.21421 35.2206 0.984375 36.3803 0.984375C37.4935 0.984375 38.3285 1.21421 38.9315 1.71984C39.5345 2.22547 39.8592 2.91497 39.952 3.83431H38.1893C38.1429 3.46657 37.9574 3.14481 37.6791 2.91497C37.4008 2.68514 36.9833 2.54724 36.3803 2.54724C35.87 2.54724 35.4989 2.63917 35.1742 2.86901C34.8959 3.09884 34.7568 3.32867 34.7568 3.65044C34.7568 3.92624 34.8495 4.11011 35.0351 4.29397C35.2206 4.47784 35.4989 4.61574 35.87 4.75364L38.0038 5.58104C38.7459 5.85684 39.3026 6.22457 39.6736 6.63827C40.0447 7.05197 40.2767 7.60357 40.2767 8.2471C40.2767 8.84467 40.1375 9.3503 39.8128 9.764C39.4881 10.1777 39.0706 10.4995 38.514 10.7753C38.0038 11.0511 37.308 11.143 36.473 11.143Z" fill="white"/>
        <path d="M46.6781 10.9591V1.71985H52.2907V2.45532H47.5594V5.90282H52.0124V6.63828H47.5594V10.1777H52.2907V10.9132H46.6781V10.9591Z" fill="white"/>
        <path d="M58.1351 11.0971C57.3002 11.0971 56.6044 10.8672 56.0478 10.4535C55.4448 10.0398 55.0273 9.44227 54.7026 8.7068C54.3779 7.97134 54.2388 7.14394 54.2388 6.22461C54.2388 5.21334 54.4243 4.33997 54.749 3.65048C55.1201 2.96098 55.5839 2.40938 56.187 2.08761C56.79 1.76584 57.4394 1.53601 58.1815 1.53601C59.1092 1.53601 59.8978 1.76584 60.5008 2.27148C61.1038 2.73114 61.5213 3.37468 61.7996 4.11014L60.8719 4.33997C60.64 3.69644 60.3153 3.23678 59.8514 2.86904C59.3876 2.50131 58.8309 2.31744 58.2279 2.31744C57.7177 2.31744 57.2074 2.45534 56.7436 2.77711C56.2797 3.05291 55.9086 3.51258 55.6303 4.11014C55.352 4.70771 55.2129 5.39721 55.2129 6.27057C55.2129 7.05201 55.3056 7.74151 55.5376 8.33907C55.7695 8.93664 56.0942 9.44227 56.5117 9.81C56.9755 10.1777 57.5321 10.3616 58.1815 10.3616C58.9701 10.3616 59.6195 10.1318 60.1297 9.71807C60.5936 9.30437 60.8719 8.7068 60.8719 8.01731H61.846C61.846 8.66084 61.7068 9.21244 61.3821 9.6721C61.0574 10.1318 60.64 10.4995 60.0833 10.7753C59.4339 11.0051 58.8309 11.0971 58.1351 11.0971Z" fill="white"/>
        <path d="M67.227 11.097C66.4848 11.097 65.8354 10.9131 65.2324 10.5914C64.6294 10.2236 64.1655 9.71801 63.8408 9.02851C63.5161 8.33901 63.3306 7.41968 63.3306 6.36244C63.3306 5.30521 63.5161 4.38588 63.8408 3.69638C64.1655 3.00688 64.6757 2.45528 65.2324 2.08754C65.8354 1.71981 66.4848 1.58191 67.227 1.58191C67.9691 1.58191 68.6185 1.76578 69.2215 2.08754C69.8245 2.45528 70.2884 2.96091 70.6131 3.69638C70.9378 4.38588 71.1233 5.30521 71.1233 6.36244C71.1233 7.41968 70.9378 8.33901 70.6131 9.02851C70.2884 9.71801 69.7782 10.2696 69.2215 10.5914C68.6649 10.9131 67.9691 11.097 67.227 11.097ZM67.227 10.3156C68.1547 10.3156 68.8968 9.99381 69.4535 9.30431C70.0101 8.66077 70.242 7.64951 70.242 6.36244C70.242 5.07538 69.9637 4.11008 69.4535 3.42058C68.8968 2.73108 68.201 2.40931 67.227 2.40931C66.2992 2.40931 65.5571 2.77704 65.0004 3.46654C64.4438 4.15604 64.1655 5.16731 64.1655 6.45438C64.1655 7.74144 64.4438 8.75271 65.0004 9.39624C65.5571 9.99381 66.2992 10.3156 67.227 10.3156Z" fill="white"/>
        <path d="M73.4891 1.71985H74.6024L79.612 9.85595H79.5192V1.71985H80.4006V10.9591H79.3337L74.2313 2.68515H74.3705V10.9591H73.4891V1.71985Z" fill="white"/>
        <path d="M86.7085 11.097C85.9664 11.097 85.317 10.9131 84.7139 10.5914C84.1109 10.2236 83.6471 9.71801 83.3224 9.02851C82.9977 8.33901 82.8121 7.41968 82.8121 6.36244C82.8121 5.30521 82.9977 4.38588 83.3224 3.69638C83.6471 3.00688 84.1573 2.45528 84.7139 2.08754C85.317 1.71981 85.9664 1.58191 86.7085 1.58191C87.4507 1.58191 88.1001 1.76578 88.7031 2.08754C89.3061 2.45528 89.77 2.96091 90.0947 3.69638C90.4194 4.38588 90.6049 5.30521 90.6049 6.36244C90.6049 7.41968 90.4194 8.33901 90.0947 9.02851C89.77 9.71801 89.2597 10.2696 88.7031 10.5914C88.1465 10.9131 87.4507 11.097 86.7085 11.097ZM86.7085 10.3156C87.6362 10.3156 88.3784 9.99381 88.935 9.30431C89.4917 8.66077 89.7236 7.64951 89.7236 6.36244C89.7236 5.07538 89.4453 4.11008 88.935 3.42058C88.3784 2.73108 87.6826 2.40931 86.7085 2.40931C85.7808 2.40931 85.0386 2.77704 84.482 3.46654C83.9254 4.15604 83.6471 5.16731 83.6471 6.45438C83.6471 7.74144 83.9254 8.75271 84.482 9.39624C85.0386 9.99381 85.7808 10.3156 86.7085 10.3156Z" fill="white"/>
        <path d="M93.0171 1.71985H94.2695L97.7948 8.47695H97.702L101.227 1.71985H102.48V10.9591H101.598V2.54725H101.784L98.0267 9.71805H97.4237L93.7593 2.59322H93.8984V10.9591H93.0171V1.71985Z" fill="white"/>
        <path d="M104.15 1.71985H105.217L108.139 6.45441H107.861L110.783 1.71985H111.85L108.464 7.14391V10.9132H107.582V7.14391L104.15 1.71985Z" fill="white"/>
      </svg>
    </flex-row>

  </div>

</template>

<script>
export default {
  name: 'Logo',
  props: {
    isMobile: Boolean
  }
}
</script>

<style scoped lang="scss">
  .logo {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    .logo-icon + .logo-text {
      margin-left: 12px;
    }
  }
  .logo.mobile {
    .logo-icon {
      width: 27px;
      height: 24px;
    }
    .logo-text {
      width: 87px;
      height: 8px;

    }
  }
</style>
