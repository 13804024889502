export const SOCIAL_NETWORKS = [
  {
    name: 'Twitter',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M14 5.06608C13.6658 5.525 13.2559 5.91621 12.7701 6.2397C12.7749 6.32646 12.7773 6.42452 12.7773 6.53388C12.7773 7.14152 12.6813 7.74989 12.4894 8.359C12.2975 8.96812 12.0044 9.55114 11.61 10.1081C11.2157 10.665 10.7459 11.1584 10.2008 11.5883C9.65562 12.0182 9.00032 12.3609 8.23487 12.6165C7.46943 12.8722 6.64907 13 5.77382 13C4.40783 13 3.14989 12.6595 2 11.9786C2.20409 11.9995 2.39983 12.01 2.58722 12.01C3.72839 12.01 4.74764 11.6855 5.64497 11.0364C5.11289 11.0274 4.63638 10.8762 4.21542 10.5827C3.79447 10.2893 3.50478 9.91434 3.34635 9.45786C3.50295 9.4854 3.6575 9.49917 3.81001 9.49917C4.02955 9.49917 4.24556 9.47273 4.45806 9.41986C3.89024 9.31486 3.41918 9.05374 3.04488 8.6365C2.6706 8.21926 2.48346 7.7377 2.48346 7.19179V7.16366C2.83199 7.34183 3.20376 7.43661 3.59876 7.44799C3.26238 7.24094 2.99569 6.97094 2.79867 6.638C2.60164 6.30505 2.50313 5.94475 2.50313 5.55708C2.50313 5.14822 2.6141 4.7675 2.83603 4.41491C3.45353 5.11455 4.20176 5.67373 5.08072 6.09243C5.9597 6.51115 6.90267 6.74369 7.90964 6.79006C7.86669 6.6248 7.84518 6.45235 7.8451 6.2727C7.8451 5.64542 8.08552 5.10982 8.56637 4.66589C9.04723 4.22196 9.62736 4 10.3067 4C11.0181 4 11.6172 4.23913 12.1042 4.7174C12.6605 4.61564 13.1815 4.4316 13.667 4.16526C13.48 4.7088 13.1195 5.1281 12.5857 5.42317C13.0763 5.36934 13.5478 5.25031 14 5.06608H14Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://twitter.com/MarsEconomy'
  },
  {
    name: 'Telegram',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M13.7767 2.478L1.95669 7.036C1.15002 7.36 1.15469 7.81 1.80869 8.01067L4.84336 8.95734L11.8647 4.52734C12.1967 4.32534 12.5 4.434 12.2507 4.65534L6.56202 9.78934H6.56069L6.56202 9.79L6.35269 12.918C6.65936 12.918 6.79469 12.7773 6.96669 12.6113L8.44069 11.178L11.5067 13.4427C12.072 13.754 12.478 13.594 12.6187 12.9193L14.6314 3.434C14.8374 2.608 14.316 2.234 13.7767 2.478Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://t.me/marseconomychat'
  },
  {
    name: 'Instagram',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M7.99995 5.91736C6.85308 5.91736 5.91714 6.8533 5.91714 8.00017C5.91714 9.14705 6.85308 10.083 7.99995 10.083C9.14683 10.083 10.0828 9.14705 10.0828 8.00017C10.0828 6.8533 9.14683 5.91736 7.99995 5.91736ZM14.2468 8.00017C14.2468 7.13767 14.2546 6.28298 14.2062 5.42205C14.1578 4.42205 13.9296 3.53455 13.1984 2.8033C12.4656 2.07048 11.5796 1.84392 10.5796 1.79548C9.71714 1.74705 8.86245 1.75486 8.00151 1.75486C7.13901 1.75486 6.28433 1.74705 5.42339 1.79548C4.42339 1.84392 3.53589 2.07205 2.80464 2.8033C2.07183 3.53611 1.84526 4.42205 1.79683 5.42205C1.74839 6.28455 1.7562 7.13923 1.7562 8.00017C1.7562 8.86111 1.74839 9.71736 1.79683 10.5783C1.84526 11.5783 2.07339 12.4658 2.80464 13.197C3.53745 13.9299 4.42339 14.1564 5.42339 14.2049C6.28589 14.2533 7.14058 14.2455 8.00151 14.2455C8.86401 14.2455 9.7187 14.2533 10.5796 14.2049C11.5796 14.1564 12.4671 13.9283 13.1984 13.197C13.9312 12.4642 14.1578 11.5783 14.2062 10.5783C14.2562 9.71736 14.2468 8.86267 14.2468 8.00017ZM7.99995 11.2049C6.22651 11.2049 4.79526 9.77361 4.79526 8.00017C4.79526 6.22673 6.22651 4.79548 7.99995 4.79548C9.77339 4.79548 11.2046 6.22673 11.2046 8.00017C11.2046 9.77361 9.77339 11.2049 7.99995 11.2049ZM11.3359 5.41267C10.9218 5.41267 10.5875 5.0783 10.5875 4.66423C10.5875 4.25017 10.9218 3.9158 11.3359 3.9158C11.75 3.9158 12.0843 4.25017 12.0843 4.66423C12.0845 4.76256 12.0652 4.85993 12.0276 4.95079C11.99 5.04166 11.9349 5.12421 11.8654 5.19373C11.7959 5.26326 11.7133 5.31838 11.6225 5.35595C11.5316 5.39352 11.4342 5.4128 11.3359 5.41267Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://www.instagram.com/mars_economy/'
  },
  /* {
    name: 'Gitbook',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64" height="16" viewBox="0 0 64 64" width="16">\n' +
      '    <switch>\n' +
      '        <path d="m28.8 47.4c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9s.9-1.9 1.9-1.9m29.4-11.6c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9m0-7.7c-3.2 0-5.8 2.6-5.8 5.8 0 .6.1 1.2.3 1.8l-19.1 10.2c-1.1-1.6-2.9-2.5-4.8-2.5-2.2 0-4.2 1.3-5.2 3.2l-17.2-9c-1.8-1-3.2-3.9-3-6.7.1-1.4.6-2.5 1.3-2.9.5-.3 1-.2 1.7.1l.1.1c4.6 2.4 19.5 10.2 20.1 10.5 1 .4 1.5.6 3.2-.2l30.8-16c.5-.2 1-.6 1-1.3 0-.9-.9-1.3-.9-1.3-1.8-.8-4.5-2.1-7.1-3.3-5.6-2.6-12-5.6-14.8-7.1-2.4-1.3-4.4-.2-4.7 0l-.7.3c-12.7 6.4-29.5 14.7-30.5 15.3-1.7 1-2.8 3.1-2.9 5.7-.2 4.1 1.9 8.4 4.9 9.9l18.2 9.4c.4 2.8 2.9 5 5.7 5 3.2 0 5.7-2.5 5.8-5.7l20-10.8c1 .8 2.3 1.2 3.6 1.2 3.2 0 5.8-2.6 5.8-5.8 0-3.3-2.6-5.9-5.8-5.9" fill="#ffffff"/>\n' +
      '    </switch>\n' +
      '</svg>\n',
    link: 'https://mars-10.gitbook.io/mars/'
  }, */
  {
    name: 'Medium',
    icon: '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M3.35663 4.84597C3.36432 4.76945 3.35383 4.69219 3.32603 4.62049C3.29823 4.54878 3.25389 4.48465 3.19663 4.43331L2.01663 3.01131V2.79797H5.68197L8.5153 9.01131L11.006 2.79797H14.5V3.01064L13.49 3.97797C13.4477 4.0109 13.415 4.05454 13.3953 4.10436C13.3756 4.15419 13.3696 4.20838 13.378 4.26131V11.372C13.3695 11.4249 13.3754 11.4791 13.3951 11.529C13.4148 11.5788 13.4476 11.6225 13.49 11.6553L14.476 12.6226V12.8353H9.51863V12.6226L10.538 11.6313C10.6393 11.5313 10.6393 11.5013 10.6393 11.3486V5.60064L7.79997 12.812H7.41663L4.1113 5.60064V10.4333C4.08397 10.6366 4.1513 10.8413 4.29463 10.9886L5.62263 12.6V12.8126H1.8573V12.6L3.1853 10.9886C3.25516 10.9158 3.30704 10.8277 3.33679 10.7313C3.36654 10.6349 3.37333 10.5328 3.35663 10.4333V4.84597Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://marseconomy.medium.com/'
  },
  {
    name: 'GitHub',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M8.00078 1C4.13283 0.998394 1 4.21483 1 8.18476C1 11.3241 2.95978 13.9927 5.68909 14.9727C6.05664 15.0675 6.00034 14.7992 6.00034 14.616V13.3709C3.87789 13.6263 3.79187 12.1836 3.64954 11.9426C3.36175 11.4381 2.68138 11.3096 2.88471 11.0686C3.368 10.8132 3.86069 11.1329 4.43157 11.9989C4.84449 12.627 5.64998 12.521 6.05821 12.4166C6.14736 12.039 6.33817 11.7016 6.60094 11.4398C4.40185 11.0349 3.48531 9.65641 3.48531 8.01767C3.48531 7.2224 3.74025 6.49139 4.24076 5.90177C3.92168 4.92977 4.27047 4.09754 4.31739 3.97384C5.22612 3.89029 6.17082 4.64219 6.24433 4.70163C6.76047 4.55864 7.35013 4.48313 8.01017 4.48313C8.67333 4.48313 9.26455 4.56185 9.78539 4.70645C9.96213 4.56828 10.838 3.92242 11.6826 4.00115C11.728 4.12486 12.0689 4.9378 11.7686 5.89695C12.2754 6.48818 12.5335 7.22562 12.5335 8.02249C12.5335 9.66445 11.6107 11.0445 9.40532 11.443C9.59421 11.6338 9.7442 11.8613 9.84651 12.1124C9.94883 12.3634 10.0014 12.6328 10.0012 12.905V14.7124C10.0137 14.857 10.0012 15 10.2358 15C13.0058 14.0409 15 11.353 15 8.18637C15 4.21483 11.8656 1 8.00078 1Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://github.com/mars-economy'
  },
  {
    name: 'Reddit',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M15 8.29017C15 7.41654 14.3011 6.71763 13.4275 6.80499C13.078 6.80499 12.6412 6.97972 12.3791 7.24181C11.156 6.45554 9.75819 5.93136 8.27301 5.93136L8.97192 2.61154L11.2434 3.13573C11.2434 3.74727 11.7676 4.18409 12.3791 4.18409C12.9906 4.18409 13.4275 3.65991 13.4275 3.04836C13.4275 2.43682 12.9033 2 12.2917 2C11.8549 2 11.5055 2.26209 11.3307 2.61154L8.88456 2H8.70983C8.62247 2 8.62246 2.08736 8.62246 2.17473L7.74883 5.93136C6.26365 5.93136 4.86583 6.36817 3.64275 7.24181C3.0312 6.63026 2.0702 6.63026 1.45866 7.24181C0.847114 7.85335 0.847114 8.81435 1.45866 9.4259C1.54602 9.51326 1.72075 9.68799 1.89548 9.68799V10.1248C1.89548 12.4836 4.60374 14.4056 8.01092 14.4056C11.4181 14.4056 14.1264 12.4836 14.1264 10.1248V9.68799C14.6505 9.4259 15 8.90172 15 8.29017ZM4.51638 9.4259C4.51638 8.81435 5.04056 8.37753 5.56474 8.37753C6.17629 8.37753 6.6131 8.90172 6.6131 9.4259C6.6131 9.95008 6.08892 10.4743 5.56474 10.4743C4.9532 10.4743 4.51638 10.0374 4.51638 9.4259ZM10.6318 12.3089C9.84555 12.8331 8.97192 13.1825 8.01092 13.0952C7.04992 13.0952 6.17629 12.8331 5.39002 12.3089C5.30265 12.2215 5.30265 12.0468 5.39002 11.8721C5.47738 11.7847 5.65211 11.7847 5.73947 11.8721C6.35101 12.3089 7.13729 12.571 7.92356 12.4836C8.70983 12.571 9.4961 12.3089 10.1076 11.8721C10.195 11.7847 10.3697 11.7847 10.5445 11.8721C10.7192 11.9594 10.7192 12.1342 10.6318 12.3089ZM10.3697 10.4743C9.75819 10.4743 9.32137 9.95008 9.32137 9.4259C9.32137 8.90172 9.84555 8.37753 10.3697 8.37753C10.9813 8.37753 11.4181 8.90172 11.4181 9.4259C11.5055 10.0374 10.9813 10.4743 10.3697 10.4743Z" fill="white" fill-opacity="0.8"/>\n' +
      '</svg>\n',
    link: 'https://www.reddit.com/r/MarsEconomy/'
  }
]
