export const ICONS = {
  statePoint: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M5 10L10 5L15 10L10 15L5 10Z" fill="white"/>\n' +
    '<path opacity="0.5" d="M9.99997 18.75C9.82896 18.7502 9.6596 18.7167 9.5016 18.6512C9.34361 18.5858 9.2001 18.4898 9.07935 18.3687L1.6306 10.9212C1.38687 10.6768 1.25 10.3458 1.25 10.0006C1.25 9.65544 1.38687 9.32437 1.6306 9.07997L9.07935 1.6306C9.32374 1.38687 9.65481 1.25 9.99997 1.25C10.3451 1.25 10.6762 1.38687 10.9206 1.6306L18.3693 9.07935C18.6131 9.32374 18.7499 9.65481 18.7499 9.99997C18.7499 10.3451 18.6131 10.6762 18.3693 10.9206L10.9206 18.3693C10.7998 18.4903 10.6563 18.5862 10.4983 18.6515C10.3403 18.7169 10.1709 18.7503 9.99997 18.75ZM9.99997 2.49997C9.99304 2.49987 9.98616 2.50115 9.97972 2.50372C9.97328 2.5063 9.96742 2.51012 9.96247 2.51497L2.5156 9.96247C2.5059 9.9723 2.50047 9.98554 2.50047 9.99935C2.50047 10.0131 2.5059 10.0264 2.5156 10.0362L9.96247 17.485C9.9723 17.4947 9.98554 17.5001 9.99935 17.5001C10.0131 17.5001 10.0264 17.4947 10.0362 17.485L17.485 10.0375C17.4947 10.0276 17.5001 10.0144 17.5001 10.0006C17.5001 9.98679 17.4947 9.97355 17.485 9.96372L10.0375 2.51497C10.0326 2.51001 10.0267 2.50607 10.0203 2.50338C10.0139 2.5007 10.0069 2.49995 9.99997 2.49997Z" fill="white"/>\n' +
    '</svg>\n',

  timePoint: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M2.49992 8.33337C2.05789 8.33337 1.63397 8.50897 1.32141 8.82153C1.00885 9.13409 0.833252 9.55801 0.833252 10C0.833252 10.4421 1.00885 10.866 1.32141 11.1786C1.63397 11.4911 2.05789 11.6667 2.49992 11.6667C3.42492 11.6667 4.16659 10.925 4.16659 10C4.16659 9.55801 3.99099 9.13409 3.67843 8.82153C3.36587 8.50897 2.94195 8.33337 2.49992 8.33337ZM19.9999 5.00004V15C19.9999 15.925 19.2583 16.6667 18.3333 16.6667H8.33325C7.89122 16.6667 7.4673 16.4911 7.15474 16.1786C6.84218 15.866 6.66658 15.4421 6.66658 15V11.6667L4.99992 10L6.66658 8.33337V5.00004C6.66658 4.55801 6.84218 4.13409 7.15474 3.82153C7.4673 3.50897 7.89122 3.33337 8.33325 3.33337H18.3333C19.2583 3.33337 19.9999 4.07504 19.9999 5.00004ZM16.4583 8.60837L15.4916 7.43337L12.4999 10.4167L11.1749 9.10004L10.2083 10.0667L12.4999 12.5667L16.4583 8.60837Z" fill="white"/>\n' +
    '<g opacity="0.5">\n' +
    '<path d="M1.66675 6.66663H3.33341V1.66663H1.66675V6.66663Z" fill="white"/>\n' +
    '<path d="M3.33341 18.3333H1.66675V13.3333H3.33341V18.3333Z" fill="white"/>\n' +
    '</g>\n' +
    '</svg>\n',

  users: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g clip-path="url(#clip0)">\n' +
    '<path d="M10.0002 10C11.8335 10 13.3335 8.50004 13.3335 6.66671C13.3335 4.83337 11.8335 3.33337 10.0002 3.33337C8.16683 3.33337 6.66683 4.83337 6.66683 6.66671C6.66683 8.50004 8.16683 10 10.0002 10ZM10.0002 10.8334C6.5835 10.8334 3.3335 13 3.3335 15C3.3335 16.6667 10.0002 16.6667 10.0002 16.6667C10.0002 16.6667 16.6668 16.6667 16.6668 15C16.6668 13 13.4168 10.8334 10.0002 10.8334Z" fill="white"/>\n' +
    '<g opacity="0.5">\n' +
    '<path d="M17.8335 13.1667C19.0002 13 20.0002 12.6667 20.0002 12.1667C20.0002 10.75 17.5835 9.16671 15.0002 9.25004H14.3335C14.1668 9.50004 14.0002 9.66671 13.8335 9.91671C15.5835 10.6667 17.0835 11.75 17.8335 13.1667Z" fill="white"/>\n' +
    '<path d="M15.0002 8.33337H14.7502C14.9168 7.83337 15.0002 7.25004 15.0002 6.66671C15.0002 5.50004 14.5835 4.41671 13.9168 3.58337C14.2502 3.41671 14.5835 3.33337 15.0002 3.33337C16.4168 3.33337 17.5002 4.41671 17.5002 5.83337C17.5002 7.25004 16.4168 8.33337 15.0002 8.33337Z" fill="white"/>\n' +
    '</g>\n' +
    '<g opacity="0.5">\n' +
    '<path d="M5 9.16671H5.66667L6.16667 9.91671C4.41667 10.6667 2.91667 11.75 2.16667 13.1667C1 13 0 12.6667 0 12.1667C0 10.75 2.41667 9.16671 5 9.16671Z" fill="white"/>\n' +
    '<path d="M5.25 8.33337H5C3.58333 8.33337 2.5 7.25004 2.5 5.83337C2.5 4.41671 3.58333 3.33337 5 3.33337C5.41667 3.33337 5.75 3.41671 6.08333 3.58337C5.41667 4.41671 5 5.50004 5 6.66671C5 7.25004 5.08333 7.83337 5.25 8.33337Z" fill="white"/>\n' +
    '</g>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0">\n' +
    '<rect width="20" height="20" fill="white"/>\n' +
    '</clipPath>\n' +
    '</defs>\n' +
    '</svg>\n',

  close: '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M0.263992 0.263992C0.615982 -0.0879975 1.18667 -0.0879975 1.53866 0.263992L11.736 10.4613C12.088 10.8133 12.088 11.384 11.736 11.736C11.384 12.088 10.8133 12.088 10.4613 11.736L0.263992 1.53866C-0.0879975 1.18667 -0.0879975 0.615982 0.263992 0.263992Z" fill="white"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.736 0.263992C12.088 0.615982 12.088 1.18667 11.736 1.53866L1.53866 11.736C1.18667 12.088 0.615982 12.088 0.263992 11.736C-0.0879975 11.384 -0.0879972 10.8133 0.263992 10.4613L10.4613 0.263992C10.8133 -0.0879975 11.384 -0.0879975 11.736 0.263992Z" fill="white"/>\n' +
    '</svg>\n',

  angleLeft: '<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M0.846191 0.809508L0.846191 1.65004C0.846191 1.83284 0.934008 2.00668 1.08276 2.11421L3.68858 3.99958L1.08276 5.88495C0.934008 5.99248 0.846191 6.16453 0.846191 6.34912L0.846191 7.18965C0.846191 7.30614 0.978813 7.37424 1.0738 7.30614L5.48255 4.11607C5.56141 4.05872 5.56141 3.94223 5.48255 3.88488L1.0738 0.694808C0.978812 0.624913 0.846191 0.693017 0.846191 0.809508Z" fill="white"/>\n' +
    '</svg>\n',

  menu: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g opacity="0.8">\n' +
    '<path d="M5.00208 7H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M5.00012 11.666H18.9946" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M9.66846 16.332H18.9946" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '</svg>\n'

}
